<template>
    <div v-if="!user.loading && user.is_staff == true">
    <h1>Mod Dashboard</h1>
    <hr />

    <h3 class="mt-2 mb-2">🔴 Live Channels</h3>
    <b-row>
    <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(xid)="data">
            <b-avatar :src="`https://assets.brimecdn.com/users/${data.item.owner.xid}/avatar`" />
          </template>

          <template #cell(title)="data">
            <span>{{ data.item.stream.title }}</span>
          </template>

          <template #cell(created)="data">
            <span>{{ formatDate(data.value*1000) }}</span>
          </template>
  
          <template #cell(status)="data">
            <b-badge :variant="status[1][data.value]">
              {{ status[0][data.value] }}
            </b-badge>
          </template>

          <template #cell(actions)="row">
            <b-button
              variant="primary"
              size="sm"
              @click="row.toggleDetails">Details</b-button>
          </template>
          <template #row-details="row">
            <b-card>
              <b-row class="mb-2">
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Stream Category : </strong>{{ row.item.stream.category_name }}
                </b-col>
              </b-row>
    
              <b-button
                size="sm"
                variant="outline-secondary"
                @click="row.toggleDetails"
              >
                Hide Details
              </b-button>
              <b-button
                size="sm"
                class="ml-1"
                variant="outline-warning"
                @click="row.toggleDetails"
                title="This will end the stream, and prevent them from going live again."
              >
                Revoke Streaming Privledges
              </b-button>
              <b-button
                size="sm"
                class="ml-1"
                variant="outline-danger"
                @click="row.toggleDetails"
                title="This will end the stream, ban the user and channel"
              >
                Ban Hammer
              </b-button>
            </b-card>
          </template>
        </b-table>
      </b-col>
    </b-row>
      <b-row>
      <b-col
        cols="12"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
   

    <h3 class="mt-2 mb-2">🦄 Magic Username Changer</h3>

    <h3 class="mt-2 mb-2">💬 Chat Log Lookup</h3>
    </div>
</template>

<script>
  import {
    BTable, BAvatar, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BFormCheckbox,
  } from 'bootstrap-vue'
const axios = require('axios')
export default {
    components: {
      BTable,
      BAvatar,
      BBadge,
      BRow,
      BCol,
      BFormGroup,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
      BFormCheckbox,
    },
    data() {
        return {
        user: {
          loading: true,
        },
        liveChannels: [],
        perPage: 10,
        pageOptions: [3, 5, 10],
        totalRows: 1,
        currentPage: 1,
        sortBy: 'follow_timestamp',
        sortDesc: true,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          {
            key: 'xid', label: '',
          },
          { key: 'slug', label: 'Username', sortable: true },
          { key: 'title', label: 'Stream Title', sortable: true },
          { key: 'created', label: 'Account Created', sortable: true },
            { key: 'actions', label: 'Actions' },
        ],
        items: [],
        status: [{
          1: true, 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
        },
        {
          1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
        }],
        }
    },
async mounted(){
    const liveLookup = await axios.get('https://api.brime.tv/v1/channels/live')
    if (this.$auth.user) {
      let user_xid = this.$auth.user.sub.replace('auth0|', '')
      const user = await axios.get(`https://api.brime.tv/v1/users/${user_xid}`)
      this.user = user.data
    }
    this.liveChannels = liveLookup.data.streams

    this.items = liveLookup.data.streams
      this.totalRows = liveLookup.data.streams.length
},
methods: {
        formatDate(date) {
      const options = {
        year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric',
      }
      return new Date(date).toLocaleDateString(undefined, options)
    },
      info(item, index, button) {
        this.infoModal.title = `Row index: ${index}`
        this.infoModal.content = JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      },
      resetInfoModal() {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
    },
}
</script>

<style>

</style>